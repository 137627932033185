.home {
  background-color: var(--color-primary-1);
}
.home.login {
  background-color: #fff;
}
.home__header {
  padding: 0 0 0 var(--gap);
  background-color: var(--color-primary-0);
  display: flex;
}
.home__headline {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0 var(--gap) 0 0;
  color: #fff;
  padding: var(--gap05) 0;
}
.home__hdrspacer {
  flex: 1;
}
.home__hdrlink {
  line-height: 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: var(--gap05);
  padding: var(--gap05) var(--gap);
}
.home__hdrlink:hover {
  background-color: var(--color-primary-4);
}
.home__hdrtext {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.home__footer {
  background-color: var(--color-primary-0);
  padding: var(--gap05) var(--gap);
}
.home__footer p {
  color: #fff;
  font-size: 1.2rem;
  line-height: 2.2rem;
  margin: 0;
}
.home__footer a {
  color: #fff;
  text-decoration: underline;
}
.home__footer em {
  color: #fff;
  font-style: normal;
}

.home__bloglist {
  display: grid;
  margin: 0 auto;
  padding: var(--gap);
  grid-gap: var(--gap);
  grid-template-columns: 1fr 1fr 1fr;
  background-color: var(--color-primary-1);
  box-sizing: border-box;
  max-width: 144rem;
}
.home__blogitem {
  list-style: none;
  margin: 0;
  background-color: #fff;
}
.home__blogitem:first-of-type {
  grid-column-start: 1;
  grid-column-end: 3;
}
.home__bloglink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap05);
  box-sizing: border-box;
  padding: var(--gap05);
  color: var(--color-complement-4);
  height: 100%;
}
.home__bloglink:hover {
  background-color: ivory;
}
.home__avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid var(--nord-snow-storm-highlight);
}
.home__avatar img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home__username {
  font-style: normal;
  margin: 0;
  color: var(--color-primary-3);
}
.home__blogtitle {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0;
}

@media (min-width: 44em) {
  .home__header {
    padding: 0 0 0 var(--gap2);
  }
  .home__headline {
    margin-right: var(--gap2);
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: var(--gap) 0;
  }
  .home__footer {
    padding: var(--gap) var(--gap2);
  }
  .home__footer p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .home__hdrlink {
    padding: var(--gap) var(--gap2);
  }
  .home__hdrtext {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
  .home__bloglist {
    padding: var(--gap2);
    grid-gap: var(--gap2);
    grid-template-columns: repeat(4, 1fr);
  }
  .home__blogitem:nth-of-type(2) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .home__bloglink {
    padding: var(--gap);
    gap: var(--gap);
  }
  .home__avatar {
    width: 6.4rem;
    height: 6.4rem;
  }
  .home__blogtitle {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media (min-width: 76em) {
  .home__bloglist {
    grid-template-columns: repeat(5, 1fr);
  }
  .home__blogitem:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .home__blogitem:nth-of-type(1) .home__avatar {
    width: 9.6rem;
    height: 9.6rem;
  }
  .home__blogitem:nth-of-type(1) .home__username,
  .home__blogitem:nth-of-type(1) .home__blogtitle {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
  .home__blogitem:nth-of-type(2) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .home__blogitem:nth-of-type(3) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .home__avatar {
    width: 8rem;
    height: 8rem;
  }
  .home__blogtitle {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}
